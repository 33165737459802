@import '../../style.scss';

.three-in-one__ {
  display: grid;
  gap: 30px;
  h1{
    font-size: 30px !important;
  }
  img {
    width: 100%;
  }
  p {
    text-align: center ;
    font-size: 16px;
  }
}

@media screen and (min-width: 48em) {
  .three-in-one__ {
    h1{
      font-size: 45px !important;
    }
    img {
    }
    p {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 64em) {
  .three-in-one__ {
    p {
      text-align: left ;
    }
  }
}
